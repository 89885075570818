define('ember-leaflet/mixins/draggability', ['exports'], function (exports) {
  'use strict';

  var Mixin = Ember.Mixin,
      observer = Ember.observer;

  exports['default'] = Mixin.create({

    draggableDidChange: observer('draggable', function () {
      if (this.get('draggable')) {
        this._layer.dragging.enable();
      } else {
        this._layer.dragging.disable();
      }
    })

  });
});