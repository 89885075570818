define('ember-leaflet/helpers/icon', ['exports'], function (exports) {
  /* global L */

  'use strict';

  var helper = Ember.Helper.helper;

  var isFastBoot = typeof FastBoot !== 'undefined';

  var icon = isFastBoot ? function () {} : function icon(_, hash) {
    return L.icon(hash);
  };

  exports.icon = icon;
  exports['default'] = helper(icon);
});