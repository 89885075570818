define('ember-leaflet/helpers/div-icon', ['exports'], function (exports) {
  /* global L */

  'use strict';

  var helper = Ember.Helper.helper;

  var isFastBoot = typeof FastBoot !== 'undefined';

  var divIcon = isFastBoot ? function () {} : function divIcon(_, hash) {
    return L.divIcon(hash);
  };

  exports.divIcon = divIcon;
  exports['default'] = helper(divIcon);
});